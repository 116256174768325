// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'angular-material-contrast-fix';

@import "styles-values.scss";

@include mat.core();

$mat-myapp-primary: (
  50 : $accent-1,
  100 : $accent-1,
  200 : $accent-1,
  300 : $accent-1,
  400 : $accent-1,
  500 : $accent-1,
  600 : $accent-1,
  700 : $accent-1,
  800 : $accent-1,
  900 : $accent-1,
  A100 : $accent-1,
  A200 : $accent-1,
  A400 : $accent-1,
  A700 : $accent-1,

  contrast: (50 : $contrast-color,
    100 : $contrast-color,
    200 : $contrast-color,
    300 : $contrast-color,
    400 : $contrast-color,
    500 : $contrast-color,
    600 : $contrast-color,
    700 : $contrast-color,
    800 : $contrast-color,
    900 : $contrast-color,
    A100 : $contrast-color,
    A200 : $contrast-color,
    A400 : $contrast-color,
    A700 : $contrast-color,
  )
);

$mat-myapp-accent: (
  50 : $accent-2,
  100 : $accent-2,
  200 : $accent-2,
  300 : $accent-2,
  400 : $accent-2,
  500 : $accent-2,
  600 : $accent-2,
  700 : $accent-2,
  800 : $accent-2,
  900 : $accent-2,
  A100 : $accent-2,
  A200 : $accent-2,
  A400 : $accent-2,
  A700 : $accent-2,

  contrast: (50 : $contrast-color,
    100 : $contrast-color,
    200 : $contrast-color,
    300 : $contrast-color,
    400 : $contrast-color,
    500 : $contrast-color,
    600 : $contrast-color,
    700 : $contrast-color,
    800 : $contrast-color,
    900 : $contrast-color,
    A100 : $contrast-color,
    A200 : $contrast-color,
    A400 : $contrast-color,
    A700 : $contrast-color,
  )
);

$warning: (
  50 : $alert-red,
  100 : $alert-red,
  200 : $alert-red,
  300 : $alert-red,
  400 : $alert-red,
  500 : $alert-red,
  600 : $alert-red,
  700 : $alert-red,
  800 : $alert-red,
  900 : $alert-red,
  A100 : $alert-red,
  A200 : $alert-red,
  A400 : $alert-red,
  A700 : $alert-red,
  contrast: (50 : $contrast-color,
    100 : $contrast-color,
    200 : $contrast-color,
    300 : $contrast-color,
    400 : $contrast-color,
    500 : $contrast-color,
    600 : $contrast-color,
    700 : $contrast-color,
    800 : $contrast-color,
    900 : $contrast-color,
    A100 : $contrast-color,
    A200 : $contrast-color,
    A400 : $contrast-color,
    A700 : $contrast-color,
  )
);


$webapp-primary: mat.m2-define-palette($mat-myapp-primary);
$webapp-accent: mat.m2-define-palette($mat-myapp-accent, A200, A100, A400);
$webapp-warn: mat.m2-define-palette($warning);

$webapp-theme: mat.m2-define-dark-theme((color: (primary: $webapp-primary,
        accent: $webapp-accent,
        warn: $webapp-warn,
      )));

@include mat.all-component-themes($webapp-theme);
@include angular-material-contrast-fix.all-component-themes($webapp-theme);

/*
.mat-mdc-raised-button.mat-accent,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-fl.mat-accent,
.mat-mdc-flat.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  color: mat.get-color-from-palette($webapp-accent, default-contrast) !important;
  --mat-mdc-button-persistent-ripple-color: mat.get-color-from-palette($webapp-accent, default-contrast) !important;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1); //Insert custom rgba contrast color
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-icon-color: mat.get-color-from-palette($webapp-accent, default-contrast);
  --mat-mdc-fab-color: mat.get-color-from-palette($webapp-accent, default-contrast);
}
*/