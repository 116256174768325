@font-face {
    font-family: 'Caveat';
    src: url('../../assets/font/Caveat/Caveat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat+Brush';
    src: url('../../assets/font/Caveat_Brush/CaveatBrush-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../assets/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Material+Symbols';
    src: url('../../assets/font/MaterialIcons-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Material+Symbols+Outlined';
    src: url('../../assets/font/MaterialIconsOutlined-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.material-symbols {
    font-family: 'Material+Symbols';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Adjust size as needed */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.material-symbols-outlined {
    font-family: 'Material+Symbols+Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 28px;
    /* Adjust size as needed */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}