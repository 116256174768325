.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
}

/* Optional spacing between items */
.hstack>*+* {
    margin-left: 8px;
    /* Adjust as needed */
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.vstack {
    display: flex;
    flex-direction: column;
}

.bar {
    min-width: 320px;
    background-color: $content-back;
}

.viewport {
    max-width: 600px;
    min-width: 320px;
    margin: 0 auto 0 auto;
}

.min-height-300 {
    min-height: 300px;
}

.content {
    background-color: $content-back;
}

/* Optional spacing between items */
.vstack>*+* {
    /*  margin-top: 8px;
    Adjust as needed */
    flex-shrink: 0;
    /* Prevent items from shrinking */
}

.zstack {
    position: relative;
}

/* Optional spacing between items */
.zstack>*+* {
    position: absolute;
    top: 0;
    left: 0;
}

.scroll-container {
    width: 100%;
    /* Adjust width as needed */
    overflow-y: auto;
    overflow-x: hidden;
    /* Enable vertical scrollbar */
}

.spacer {
    flex-grow: 1;
    /* Allow spacer to grow and fill remaining space */
}

.spacer-xs {
    height: 4px;
    width: 4px;
}

.spacer-s {
    height: 8px;
    width: 8px;
}

.spacer-m {
    height: 12px;
    width: 12px;
}

.spacer-l {
    height: 16px;
    width: 16px;
}

.spacer-xl {
    height: 24px;
    width: 24px;
}

.spacer-xxl {
    height: 32px;
    width: 32px;
}

.spacer-xxxl {
    height: 48px;
    width: 48px;
}

.spacer-xxxxl {
    height: 64px;
    width: 64px;
}

.padval {
    padding: var(--padding);
}

.maval {
    margin: var(--margin);
}

.radiusval {
    img {
        -moz-border-radius: 0px;
        -webkit-border-radius: var(--radius);
        border-radius: var(--radius);
    }
}

.dimval {
    width: var(--w);
    height: var(--h);
}

.container-height {
    height: calc(100vh - var(--minus));
}

.imgval {
    width: var(--w);
    height: var(--h);

    img {
        object-fit: cover;
        width: var(--w);
        height: var(--h);
    }
}