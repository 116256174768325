.calendar {
    width: auto;
    max-width: 308px;
    margin: auto;

    .cal-title {
        width: 100%;

        .nav-icon {
            background-color: $accent-2;
            padding: 0;
            margin: 0;
        }

        .cal-title {
            display: inline-block;
            max-width: 73%;

            text-align: center;
            padding: 0;
            margin: 0;
        }

        margin-bottom: 12px;
    }

    .sel-desc {
        width: 100%;
        text-align: end;
        vertical-align: bottom;
        min-height: 30px;
        margin: 30px 0 10px 0;
    }

    .day {
        width: 40px;
        height: 40px;
        background-color: $accent-3-verylight;
        display: inline-block;
        text-align: center;
        padding-top: 5px;
        position: relative;
        margin: 2px;
    }

    .past {
        color: $font-color-sec-light;
    }

    .occupied {
        color: $font-color-sec-light;
        text-decoration: line-through;
    }

    .occupied-selection {
        background: $accent-2;
        width: 30px;
        height: 30px;
        margin: auto;
        border-radius: 50%;
        color: #fff;
    }

    .selected {
        background: $accent-1;
        width: 30px;
        height: 30px;
        margin: auto;
        border-radius: 50%;
        color: #fff;
    }

    .weekDay {
        width: 40px;
        display: inline-block;
        text-align: center;
        margin: 2px;
        // background-color: $accent-2-light;
    }
}

#business-dashboard-room {
    .calendar {
        width: 308px;
        min-height: 450px;
        margin: 0;

        .cal-title {
            width: 100%;

            .nav-icon {
                background-color: $accent-2;
                padding: 0;
                margin: 0;
            }

            .cal-title {
                margin: auto;
                display: inline-block;
                width: 220px;
                max-width: 73%;

                text-align: center;
                padding: 0;
                margin: 0;
            }

            margin-bottom: 30px;
        }
    }
}

@media only screen and (max-width: 800px) {


    #business-dashboard-room {
        .calendar {
            margin: auto;
        }
    }
}