html,
body {
    height: 100%;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    color: $font-color-prim;
    font-weight: 500;
    line-height: 30px;
    background: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h4,
h5,
h6 {
    line-height: 1.6;
}

h1 {
    font-size: 36px;
    color: $font-color-sec;
    line-height: 46px;
}

h2 {
    font-size: 26px;
    color: $font-color-sec;
    line-height: 36px;
}

h3 {
    font-size: 20px;
    color: $font-color-sec;
}

h4 {
    font-size: 18px;
    color: $font-color-sec;
}

h5 {
    font-size: 16px;
    color: $font-color-prim;
}

h6 {
    font-size: 14px;
    color: $font-color-prim;
}

.hint {
    font-size: 10px;
    color: $font-color-prim;
}

li {
    margin-top: 8px;
    color: $font-color-prim;
}

p {
    margin: 0;
    padding: 0;
}

.spinner-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $accent-3-verylight;

    .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -25px;
    }
}

button {
    font-weight: 600 !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;

    margin-left: 4px;
    margin-left: 4px;
}

.hand {
    font-family: 'Caveat', sans-serif;
}

.italic {
    font-style: italic;
}

a {
    color: $font-color-sec;
    text-decoration: none;

    fa-icon {
        padding-left: 10px;
    }
}

.isButton {
    cursor: pointer;
}

.material-symbols-outlined {
    color: $font-color-sec;
    vertical-align: bottom;
    padding: 0px;
    margin: 12px 12px 4px 0;
}

.enumeration {
    .material-symbols-outlined {
        font-size: 22px;
    }

    img {
        margin: 12px 10px 0 0;
        vertical-align: bottom;
    }
}

.mat-icon {
    .material-symbols-outlined {
        padding: 0px;
        margin: 0px;
        font-size: 24px;
    }
}

.login-button {
    border-radius: 6px;
    border-color: $accent-2;
    border-width: 1px;
    border-style: solid;
    padding: 12px 24px 8px 24px;
    min-width: 260px;
    cursor: pointer;

    span {
        padding: 0;
        margin: 0;
    }

    img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-bottom: 4px;
        vertical-align: middle;
    }
}

.line-breaks {
    white-space: pre-line;
}

.file-upload::-webkit-file-upload-button {
    visibility: hidden;
}

input[type='file'] {
    opacity: 0;
    width: 100%;
}

.upload-icon {
    background-color: $accent-1;
    font-size: 40px;
    color: $contrast-color;
    border-radius: 3px;
    margin: 0;
    padding: 0;
}

.action-icon {
    // background-color: $accent-1;
    font-size: 30px;
    color: $accent-1;
    padding: 4px;
    margin: 0;
}

.tag-elements {
    font-size: 12px;
    padding: 8px 8px 8px 8px;
    border-radius: 8px;
    font-weight: 800;
    color: #fff;
    rotate: -15deg;
    text-align: center;
    line-height: 18px;
}

.tag-elements-date {
    font-size: 12px;
    padding: 2px 8px 2px 8px;
    font-weight: 800;
    color: #333;
    text-align: center;
    line-height: 18px;
    background-color: $font-color-prim;
}

.unavailable {
    background-color: $alert-red;
    pointer-events: none;
    opacity: 0.9;
}

.reserved {
    background-color: $alert-yellow;
    pointer-events: none;
    opacity: 0.9;
}

.nav-icon {
    background-color: $accent-2;
    font-size: 40px;
    color: $contrast-color;
    border-radius: 3px;
    margin: 0;
    padding: 0;
}

.header {

    max-width: 600px;
    min-width: 320px;
    margin: 0 auto 0 auto;

    .action-icon {
        color: $accent-2;
        padding-bottom: 0px;
    }

    .account-icon {
        background-color: $accent-2;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        width: 30px;
        height: 30px;
        opacity: 0.8;
        color: $contrast-color;
        margin-right: 8px;
        margin-top: 6px;
        margin-bottom: 2px;
        border-radius: 20px;
    }
}

.footer {
    .account-icon {
        background-color: $font-color-sec;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        padding-top: 0px;
        width: 30px;
        height: 30px;
        opacity: 0.8;
        color: $contrast-color;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6px;
        margin-bottom: 2px;
        border-radius: 20px;
    }

    .footer-icon {
        // background-color: $accent-1;
        font-size: 34px;
        color: $font-color-sec;
        padding: 0;
        margin: 6px 0 0 0;
        opacity: 0.8;
        width: 80px;
        text-align: center;
    }

    .footer-text {
        color: $font-color-sec;
        font-size: 10px;
        padding: 0;
        margin: -6px 0 0 0;
        text-align: center;
    }

    .badge {
        margin-top: 22px;
        margin-left: 18px;

        p {
            margin-top: -5px;
        }
    }
}

.menuItem {
    color: $font-color-sec;
    font-weight: 600;
    cursor: pointer;
    padding: 12px;
    font-size: 16px;
    margin: 0;

    p {
        margin: 0;
        padding: 0;
    }

    .menu-badge {
        margin-left: 8px;
        color: $contrast-color;
        background-color: $accent-1;
        padding: 1px 6px 1px 6px;
        border-radius: 20px;
    }
}

.menuItem:hover {
    background: $accent-2-light;
}

.badge {
    padding: 0;
    margin: 0;
    height: 20px;
    font-weight: 600;
    width: 20px;
    pointer-events: none;

    p {
        padding: 0;
        margin: 0;
        margin-top: -5px;
    }

    text-align: center;
    font-size: 14px;
    color: $contrast-color;
    border-radius: 50%;
    background-color: $accent-1;
}

.disabled {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    opacity: 0.6;
}

.bold {
    font-weight: 600;
}

.superbold {
    font-weight: 800;
}

.logo-img {
    rotate: -12deg;

    img {
        object-fit: contain !important;
        margin-left: 8px;
    }
}

@keyframes pulsate {
    100% {
        /* Larger blur radius */
        text-shadow:
            0 0 4px #fff,
            0 0 11px #fff,
            0 0 19px #fff,
            0 0 40px $accent-1,
            0 0 80px $accent-1,
            0 0 90px $accent-1,
            0 0 100px $accent-1,
            0 0 150px $accent-1;
    }

    0% {
        /* A slightly smaller blur radius */
        text-shadow:
            0 0 4px #fff,
            0 0 10px #fff,
            0 0 18px #fff,
            0 0 38px $accent-1,
            0 0 73px $accent-1,
            0 0 80px $accent-1,
            0 0 94px $accent-1,
            0 0 140px $accent-1;
    }
}

.welcome-text {
    font-size: 100px;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 70px;

    animation: pulsate 0.11s ease-in-out infinite alternate;
}

.welcome {
    text-align: center;

    img {
        object-fit: contain !important;
        rotate: 35deg;
        height: 200px;
        opacity: 0.6;
        width: 200px;
        margin-right: 200px;
    }
}

.welcome-banner {
    background-color: $accent-1;
    padding: 12px 18px 12px 18px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: $contrast-color;
    border-radius: 60px;
    rotate: -4deg;
}

.logo-text {
    font-family: 'Caveat', sans-serif;
    font-size: 26px;
    color: $accent-2;
    opacity: 1;
    padding-top: 12px;
    padding-left: 0px;
}

.divider {
    height: 0.5px;
    background-color: $font-color-sec-light;
    width: 100%;
}

.center-horizontal {
    display: flex;
    align-items: center;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-vertical {
    display: flex;
    justify-content: center;
}

.red {
    background-color: red;
}

.blue {
    background-color: blue;
}

.green {
    background-color: green;
}

.white {
    background-color: white;
}

.social-media-icon {
    height: 40px;
    width: 40px;
}

.accent-3-verylight {
    background-color: $accent-3-verylight;
}

.accent-2-verylight {
    background-color: $accent-2-light;
}

.accent-1-verylight {
    background-color: $accent-1-light;
}

.accent-3-back {
    background-color: $accent-3;
}

.accent-2-back {
    background-color: $accent-2;
}

.accent-1-back {
    background-color: $accent-1;
}

.accent-1 {
    color: $accent-1;
}

.accent-2 {
    color: $accent-2;
}

.accent-3 {
    color: $accent-3;
}

.alert-red {
    color: $alert-red;
}

.go-green {
    color: $go-green;
}

.go-green-background {
    background-color: $go-green;
}

.alert-yellow {
    color: $alert-yellow;
}

.alert-red-back {
    background-color: $alert-red;
}

.alert-yellow-back {
    background-color: $alert-yellow;
}

.prim-color {
    color: $font-color-prim;
}

.sec-color {
    color: $font-color-sec;
}

.sec-color-light {
    color: $font-color-sec;
    opacity: 0.8;
}