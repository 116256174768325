$contrast-color: #fff;

$font-color-prim: #ccc;
$font-color-prim-light: #cccccca5;
$font-color-sec: #aaaaaa;
$font-color-sec-light: #aaaaaa36;

$accent-3: rgba(#222, 1); //rgb(224, 211, 193);
$accent-3-light: rgba($accent-3, 0.9);
$accent-3-verylight: rgba($accent-3, 0.7);
$content-back: rgba($accent-3, 1);

/*
$content-back: #fff;
$contrast-color: #fff;

$font-color-prim: #222222;
$font-color-prim-light: #222222a5;
$font-color-sec: #555555;
$font-color-sec-light: #55555536;
$accent-3: rgba(#d4be84, 1); //rgb(224, 211, 193);
$accent-3-light: rgba($accent-3, 0.35);
$accent-3-verylight: rgba($accent-3, 0.1);

$accent-2: rgba(#67A0B4, 1); // rgba(170, 194, 190); // 
$accent-2-light: rgba($accent-2, 0.15);
*/

$accent-1: #f87d49; //rgba(255, 0, 153, 0.768); //rgba(246, 126, 69); //#F67E45 f87d49//pink #f09
$accent-1-light: rgba($accent-1, 0.15);
$accent-2: rgba(#52a1bd, 1); // rgba(170, 194, 190); // 67A0B4
$accent-2-light: rgba($accent-2, 0.25);

$alert-red: #e74c3c;
$alert-yellow: #f1c40f;

$go-green: #2ecc71;