#gallery-container {

    .material-symbols-outlined {
        font-size: 40px;
    }

    background-color: #000000cc;

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        background-color: transparent;
        font-weight: 800;
        color: white;
        cursor: pointer;
    }

    .previous-image {
        position: absolute;
        left: 0;
        top: 40vh;
        padding: 0 0 0 20px;
        background-color: transparent;
        font-weight: 800;
        color: white;
        cursor: pointer;
    }

    .next-image {
        position: absolute;
        right: 0;
        top: 40vh;
        padding: 0 0 0 0;
        background-color: transparent;
        font-weight: 800;
        color: white;
        cursor: pointer;
    }

    .preview-container {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        height: 30%;

        overflow-x: auto;

        .preview-image {
            margin: 10px;
            cursor: pointer;

            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
            }
        }
    }

    .image-container {

        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
        width: 100%;
        overflow: hidden;

        #currentImage {
            object-fit: contain;
            width: auto;
            margin-top: auto;
            height: 90%;

            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;
        }

    }
}