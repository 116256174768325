.chat {
    .message-block-left {
        width: 100%;
        text-align: start;
        margin-top: 16px;
    }

    .message-left {
        width: auto;
        max-width: 80%;
        padding: 10px 20px 10px 20px;
        margin-right: auto;
        overflow-wrap: break-word;
        border: 0.5px solid $accent-1;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
        background-color: $accent-1-light;
    }

    .message-block-right {
        width: 100%;
        text-align: end;
        margin-top: 16px;
    }

    .message-right {
        width: auto;
        max-width: 80%;
        padding: 10px 20px 10px 20px;
        margin-left: auto;
        overflow-wrap: break-word;
        border: 0.5px solid $accent-2;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        background-color: $accent-2-light;
    }

    .mat-form-field {
        margin: 0;
        padding: 0;
        width: 100% !important;
    }

    textarea {
        width: 100%;
        border-radius: 6px;
        overflow-y: auto;
    }
}